<template>
  <div> 
    <b-row class="m-0  justify-content-center">
      <b-col
        class="content-header"
        cols="9"
      >
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ ($route.meta.pageTitle[$route.meta.pageTitle.length-1] =='s' ? $route.meta.pageTitle.substring(0, $route.meta.pageTitle.length - 1) : $route.meta.pageTitle) }} 
        </h2>
        <div class="content-header-search mr-1">
          <h3 class="m-0">{{companyForm.isParticular ?  (companyForm.civility==0 ? 'M. ' : companyForm.civility==1 ? 'Mme ' : '')+ companyForm.firstName+ ' ' + companyForm.lastName : companyForm.name}}</h3>
        </div>
        <div class="content-header-actions d-flex">
            <feather-icon class="cursor-pointer" icon="XIcon" size="24" @click="cancel()" /> 
        </div>
      </b-col>
    </b-row>
    <div v-if="isLoadingCompany" class="content-loader-center m-0 h-100">
      <div class="text-center flex-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
        <br />
        Chargement du formulaire...
      </div>
    </div>
    <b-row v-else class="content-scrollable-sticky m-0 h-100 justify-content-center" >
      <!-- <b-col cols="3" class="content-scrollable-sticky-nav">
        <ul>
          <li><a href="#general" class="current">Général</a></li>
          <li><a href="#juridique">Juridique</a></li>
        </ul>
      </b-col> --> 
      <b-col cols="12" lg="9" md="10" sm="12"  class="content-scrollable-sticky-main">
        <validation-observer ref="formCreateCompany" autocomplete="off" >
          <b-form autocomplete="off" >
            <div id="general" class="my-0">
              <b-row class="my-0">
                <b-col class="d-flex align-items-center" cols="10">
                    <b-form-group label-for="CustomerType">
                        <validation-provider #default="{ errors }" name="CustomerType">
                            <b-form-checkbox v-model="companyForm.isParticular">Particulier ?</b-form-checkbox><small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col class="d-flex justify-content-end align-items-center" cols="2">
                    <b-form-group>
                        <validation-provider #default="{ errors }" name="Color">
                          <v-swatches
                            :state="errors.length > 0 ? false : null" 
                            v-model="companyForm.color"
                            show-fallback
                            popover-x="left"
                            id="color"
                            :show-border="false"
                            :swatch-size="32"
                            :trigger-style="{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' }"
                            :swatch-style="{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' }"
                            :wrapper-style="{ paddingLeft: '8px', paddingRight: '0px' }"
                          ></v-swatches> 
                        </validation-provider>
                    </b-form-group>
                </b-col> 
              </b-row>
              <b-row v-if="!companyForm.isParticular" class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group label="Nom de la société *" label-for="name">
                    <validation-provider #default="{ errors }" name="name" :rules="!companyForm.isParticular ? 'required' : ''">
                      <vue-typeahead-bootstrap
                        state="errors.length &gt; 0 ? false : null"
                        id="name"
                        v-model="companyForm.name"
                        :disableSort="false"
                        :ieCloseFix="false"
                        :data="autocompleteCompanyNameData"
                        :showAllResults="true"
                        :serializer="item => item.nom_raison_sociale ? item.nom_raison_sociale: item.nom_complet"
                        placeholder="Saisissez ou Rechercher une entreprise par nom ou par numéro de siret"
                        @input="autocompleteCompanyNameSource"
                        @hit="autocompleteCompanyNameSelected"
                        aria-autocomplete="both" aria-haspopup="false" autocapitalize="off" autocomplete="off" autocorrect="off" autofocus= "" role="combobox" correcteur orthographique="false"
                      >
                        <template slot="suggestion" slot-scope="{ data, htmlText }">
                          <div class="item"> 
                            <span>{{(data.nom_raison_sociale ? data.nom_raison_sociale: data.nom_complet)}}</span> 
                            <small>{{(data.siege.numero_voie? data.siege.numero_voie +' ' : '')+(data.siege.type_voie? data.siege.type_voie +' ' : '')+(data.siege.libelle_voie? data.siege.libelle_voie +' ' : '')+', '+data.siege.code_postal+ ' '+data.siege.libelle_commune}}</small> 
                          </div>
                        </template>
                      </vue-typeahead-bootstrap>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>  
              <b-row v-else class="my-0">
                  <b-col cols="4" class="py-0">
                      <b-form-group label="Civilité" label-for="civility">
                          <validation-provider #default="{ errors }" name="Civility">
                              <v-select class="mb-1" id="civility" :state="errors.length &gt; 0 ? false : null" :reduce="type =&gt; type.value" v-model="companyForm.civility" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="optionCivility"></v-select><small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </b-form-group>
                  </b-col>
                  <b-col cols="4" class="py-0 mb-1">
                      <b-form-group label="Prénom " label-for="firstName">
                          <validation-provider #default="{ errors }" name="Firstname">
                              <b-form-input  id="firstName" v-model="companyForm.firstName" @input="companyForm.name = companyForm.firstName +' '+companyForm.lastName" :state="errors.length &gt; 0 ? false : null"></b-form-input>
                          </validation-provider>
                      </b-form-group>
                  </b-col>
                  <b-col cols="4" class="py-0 mb-1">
                      <b-form-group label="Nom *" label-for="lastName">
                          <validation-provider #default="{ errors }" name="Lastname" :rules="companyForm.isParticular ? 'required' : ''">
                              <b-form-input id="lastName" v-model="companyForm.lastName" @input="companyForm.name = companyForm.firstName +' '+companyForm.lastName" :state="errors.length &gt; 0 ? false : null"></b-form-input><small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </b-form-group>
                  </b-col>
              </b-row>
              <div v-for="(itemAddress, indexAddress) in companyForm.addresses" :key="indexAddress">
                  <b-row class="my-0"> 
                    <b-col cols="12" class="py-0 mb-1">
                      <b-form-group :label-for="'labelAddress_' + indexAddress">
                        <template slot="label">
                            <div class="d-flex w-100 justify-content-between  align-items-center">
                                <label :for="'labelAddress_' + indexAddress" :class="companyForm.addresses.length > 1 ? 'font-weight-bolder':''">{{companyForm.addresses.length > 1 ? 'Adresse ' + (indexAddress + 1) : 'Adresse' }}</label>
                                <div class="d-flex" v-if="companyForm.addresses.length > 1">
                                    <div v-if="itemAddress.isDefault" class="d-flex justify-space-center align-items-center" >
                                      cette adresse est l'adresse principale
                                      <span class="link_icon material-icons-outlined text-success m-0" style="font-size:18px">
                                        done
                                      </span>
                                      <!-- <span class="link_icon material-icons-outlined m-0 text-primary " style="font-size:18px">
                                        star
                                      </span> -->
                                    </div>
                                    <div v-else class="d-flex justify-space-center align-items-center  text-primary cursor-pointer" @click="addFavoriteAddress(indexAddress)">
                                      définir comme adresse principale
                                      <span class="link_icon material-icons-outlined m-0 cursor-pointer" style="font-size:18px">
                                        grade
                                      </span>
                                    </div>
                                    <span class="link_icon material-icons-outlined m-0 cursor-pointer" @click="deleteAddress(itemAddress, indexAddress)" style="font-size:18px">
                                      clear
                                    </span>
                                </div>
                            </div>
                        </template>
                        <validation-provider #default="{ errors }" name="LabelAddress">
                            <b-form-input v-if="companyForm.addresses.length &gt; 1" :id="'labelAddress_' + indexAddress" v-model="itemAddress.label" :state="errors.length &gt; 0 ? false : null" placeholder="Libellé (optionnel)" class="mb-1"></b-form-input>
                        </validation-provider>
                        <validation-provider #default="{ errors }" name="Address">
                          <vue-typeahead-bootstrap
                            type="text"
                            :state="errors.length &gt; 0 ? false : null"
                            class="not-autocomplete" :id="'address_' + indexAddress"
                            v-model="itemAddress.address"
                            :ieCloseFix="false"
                            :data="autocompleteAddressData"
                            :serializer="item => item.properties.label"
                            :showAllResults="true"
                            placeholder="Rechercher une adresse ou saisissez : Rue et n° de rue"
                            @input="autocompleteAddressSource(indexAddress)"
                            @hit="autocompleteAddressSelected"
                            autocomplete="nope" aria-autocomplete="nope"
                          >
                            <template slot="suggestion" slot-scope="{ data, htmlText }">
                              <div class="item"> 
                                <small>{{data.properties.name}}</small> 
                                <span>{{data.properties.postcode+ ' ' + data.properties.city}}</span> 
                              </div>
                            </template>
                          </vue-typeahead-bootstrap>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" class="py-0 mb-1">
                      <validation-provider #default="{ errors }" name="AddressComplement">
                          <b-form-input :id="'addressComplement_' + indexAddress" v-model="itemAddress.addressComplement" :state="errors.length &gt; 0 ? false : null" placeholder="Bâtiment, Appartement.."></b-form-input>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="my-0">
                    <b-col cols="3" class="py-0 mb-1">
                        <validation-provider #default="{ errors }" name="Zipcode">
                            <b-form-input :id="'zipcode_' + indexAddress" v-model="itemAddress.zipCode" :state="errors.length &gt; 0 ? false : null" placeholder="Code postal"><small class="text-danger">{{ errors[0] }}</small></b-form-input>
                        </validation-provider>
                    </b-col>
                    <b-col cols="9" class="py-0 mb-1">
                        <validation-provider #default="{ errors }" name="City">
                            <b-form-input :id="'city_' + indexAddress" v-model="itemAddress.city" :state="errors.length &gt; 0 ? false : null" placeholder="Ville"><small class="text-danger">{{ errors[0] }}</small></b-form-input>
                        </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="my-0">
                    <b-col cols="12" class="py-0 mb-1">
                      <validation-provider #default="{ errors }" name="Country">
                          <b-form-input :id="'country_' + indexAddress" v-model="itemAddress.country" :state="errors.length &gt; 0 ? false : null" placeholder="Pays"><small class="text-danger">{{ errors[0] }}</small></b-form-input>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="my-0">
                    <b-col cols="12" class="py-0">
                      <label for="address'">Coordonnées GPS</label>
                    </b-col>
                    <b-col cols="3" class="py-0 mb-1">
                        <validation-provider name="latitude">
                            <b-form-input :id="'latitude_' + indexAddress" v-model="itemAddress.latitude" placeholder="Latitude"></b-form-input>
                        </validation-provider>
                    </b-col>
                    <b-col cols="3" class="py-0 mb-1">
                        <validation-provider name="longitude">
                            <b-form-input :id="'longitude_' + indexAddress" v-model="itemAddress.longitude" placeholder="Longitude"></b-form-input>
                        </validation-provider>
                    </b-col>
                  </b-row>  
              </div>

              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <small class="cursor-pointer text-primary" @click="addAddress">+ Ajouter une adresse</small>
                </b-col>
              </b-row>
              <b-row class="my-0">
                  <b-col cols="4" class="py-0 mb-1">
                    <b-form-group label="Email" label-for="email">
                      <validation-provider #default="{ errors }" name="Email">
                          <b-form-input id="email" v-model="companyForm.email" :state="errors.length &gt; 0 ? false : null"></b-form-input><small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="4" class="py-0 mb-1">
                    <b-form-group label="Téléphone principal" label-for="phoneNumber">
                      <validation-provider #default="{ errors }" name="PhoneNumber">
                          <b-form-input id="phoneNumber" type="text"  v-model="companyForm.phoneNumber" v-mask="'## ## ## ## ##'" :state="errors.length &gt; 0 ? false : null"><small class="text-danger">{{ errors[0] }}</small></b-form-input>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="4" class="py-0 mb-1">
                    <b-form-group label="Téléphone secondaire" label-for="secondaryPhoneNumber">
                      <validation-provider #default="{ errors }" name="SecondaryPhoneNumber">
                          <b-form-input id="secondaryPhoneNumber" type="text"  v-model="companyForm.secondaryPhoneNumber" v-mask="'## ## ## ## ##'" :state="errors.length &gt; 0 ? false : null" ><small class="text-danger">{{ errors[0] }}</small></b-form-input>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group label="Contacts associés" label-for="contactIds">
                      <validation-provider #default="{ errors }" name="ContactIds">
                          <v-select id="contactIds" multiple="multiple" :loading="isLoadingContact" :state="errors.length &gt; 0 ? false : null" v-model="companyForm.contactIds" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="contactsList.map(elem => { return { label: elem.firstName+' '+elem.lastName, value: elem.id }              })              " :reduce="elem =&gt; elem.value"><template v-slot:no-options=""><template>Aucun résultat trouvé</template></template>
                              <li class="border-bottom p-1 py-50" slot="list-header">
                                <b-button
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  @click="newContact()"
                                  variant="primary"
                                  size="sm"
                                  class="w-100 d-flex justify-content-center align-items-center"
                                >
                                  + Nouveau contact
                                  <span class="link_icon material-icons-outlined text-white m-0 ml-50" style="font-size:13px">
                                    open_in_new
                                  </span>
                                </b-button>
                              </li>
                          </v-select><small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" class="py-0 mb-1">
                  <small class="cursor-pointer text-primary" @click="displayMore = !displayMore">{{ displayMore ? '- Masquer les informations juridiques' : '+ Afficher les informations juridiques' }} </small>
                </b-col>
              </b-row>
            </div>
            <div id="juridique" class="my-0" v-if="displayMore">
              <b-row class="my-0">
                <b-col cols="6" class="py-0 mb-1" v-if="!companyForm.isParticular">
                  <b-form-group label="Siret" label-for="siret">
                      <validation-provider #default="{ errors }" name="Siret">
                          <b-form-input id="siret" v-model="companyForm.siret" :state="errors.length &gt; 0 ? false : null"></b-form-input>
                      </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="py-0 mb-1" v-if="!companyForm.isParticular">
                  <b-form-group label="Numéro de TVA" label-for="tvaNumber">
                      <validation-provider #default="{ errors }" name="TvaNumber">
                          <b-form-input id="tvaNumber" v-model="companyForm.tvaNumber" :state="errors.length &gt; 0 ? false : null"></b-form-input>
                      </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group label="Compte client" label-for="companyAccount">
                      <validation-provider #default="{ errors }" name="CustomerAccount">
                          <b-form-input id="companyAccount" v-model="companyForm.companyAccount" :state="errors.length &gt; 0 ? false : null"></b-form-input>
                      </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group label="Notes" label-for="notes">
                      <validation-provider #default="{ errors }" name="Notes">
                          <b-form-textarea id="notes" v-model="companyForm.notes" :state="errors.length &gt; 0 ? false : null" rows="3"></b-form-textarea>
                      </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>

        <b-row class="my-0">
          <b-col cols="12" class="py-0">
            <div
              class="d-flex justify-content-between mt-2"
              style="border-top: 1px solid #e2e2e2; padding-top: 0.5rem;"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  @click="cancel()"
                >
                  <feather-icon icon="ArrowLeftIcon" />
                  Annuler
                </b-button>
                <b-button
                  v-if="companyForm.id"
                  variant="outline-danger"
                  class="btn-icon ml-2"
                  @click="archiveCompanyLocal(companyForm.id, companyForm.name)"
                >
                    <feather-icon icon="ArchiveIcon" /> 
                </b-button>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="create"
              >
                <feather-icon icon="SaveIcon" v-if="companyForm.id" />
                <feather-icon icon="PlusIcon" v-else />
                {{ companyForm.id ? 'Modifier' : 'Ajouter' }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>  


    
  </div>
</template>
<script>
import {debounce} from 'lodash';
import Autocomplete from 'vuejs-auto-complete'
import vSelect from "vue-select";
import { ECivility } from "@/types/api-orisis/enums/enums";
import Ripple from "vue-ripple-directive";
import { Trash2Icon, StarIcon, CheckCircleIcon } from "vue-feather-icons";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";


configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton, VBTooltip } from "bootstrap-vue";
import { mask } from "vue-the-mask";


export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      scrolled: false,
      autocompleteCompanyName:"", 
      autocompleteCompanyNameData:[],
      autocompleteAddressData:[],
      autocompleteAddressIndex:0,
      optionCivility: [
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },
        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
      required,
      url,
      email,
      displayMore: false,
      dataOrigine:{},
      companyForm: {},
      companyType:{}
    }
  },
  created(){
    this.popupCompanyEvent(this.id)
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  methods: {
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(".content-scrollable-sticky-nav ul li a");
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach(link => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    deteleAutocomplete(){
      let elements = document.querySelectorAll('.not-autocomplete input');
      if (!elements) {
        return;
      }

      elements.forEach(element => {
        element.setAttribute("autocomplete", "nope");

      });
    },
    addAddress() {
      this.companyForm.addresses.push({
        address: "",
        addressComplement: "",
        zipCode: "",
        city: "",
        country: "",
        label: "",
        isDefault: this.companyForm.addresses.length>0 ? false : true,
        id:0
      });
      this.$nextTick(() => {
        this.deteleAutocomplete()
      });
    },
    addFavoriteAddress(index) {
      for (let i = 0; i < this.companyForm.addresses.length; i++) {
        const address = this.companyForm.addresses[i];
        if (i == index) {
          address.isDefault = true;
        } else {
          address.isDefault = false;
        }
      }
    },
    deleteAddress(item, index) {
      this.companyForm.addresses.splice(index, 1);
    },
    popupCompanyEvent(id) { 
      this.fetchContactsList(); 
      if (id>0) {
        this.fetchCompany(id).then(res => {
          this.companyForm = res
          this.companyForm.companyTypeId=res.companyType.id
          this.companyForm.contactIds=res.contacts ? res.contacts.map(elem => { return elem.id  }) : null
          this.companyType=res.companyType
          delete this.companyForm.companyType
          delete this.companyForm.contacts
          this.dataOrigine=JSON.stringify(this.companyForm)
          // this.companyForm.jobId = res.job.id
          // this.companyForm.contractId = res.contract.id
        })
      }else{
        this.initializeForm()
      }
    },
    initializeForm () {
      this.companyForm = {
        id: 0,
        addresses: [],
        contactIds: [],
        color: '',
        email: '',
        phoneNumber: '',
        secondaryPhoneNumber: '',
        siret: '',
        tvaNumber: '',
        isParticular: false,
        name: '',
        civility: 0,
        lastName: '',
        firstName: '',
        notes: '',
        companyAccount: '',
        companyTypeId:this.$route.params.companyTypeId,
        companyType:{ name:this.$route.params.name, id:this.$route.params.companyTypeId },
        workspaceId: ''
      }
      this.companyType = { 
        label:this.$route.params.name, 
        id:this.$route.params.companyTypeId 
      }
      this.dataOrigine=JSON.stringify(this.companyForm)
    },
    ...mapActions([
      "createCompany",
      "fetchContactsList",
      "fetchCompany",
	    "updateCompany",
      "archiveCompany"
    ]),
    create (e) {
      e.preventDefault()
      this.$refs.formCreateCompany
        .validate() 
        .then(success1 => {
          if (success1) {
            if (this.companyForm.id) 
              this.updateCompany(this.companyForm)
            else 
              this.createCompany(this.companyForm)
            
            this.$nextTick(() => {
              if(this.$route.params.routeOrigine){
                this.$tabs.close({to : "/"+this.$route.params.routeOrigine})
              }else{
                this.$tabs.close({to : "/directory/third/"+this.companyType.label.toLowerCase()+"/"+this.companyType.id})
              }
              
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // Autocompte Company from data.gouv
    autocompleteCompanyNameSource: debounce(function(){
      let _this = this
      fetch(`https://recherche-entreprises.api.gouv.fr/search?q=${this.companyForm.name}&page=1&per_page=100`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.autocompleteCompanyNameData = data.results;
        })
    }, 500),
    autocompleteCompanyNameSelected (item) {
      this.companyForm.siret=item.siege.siret
      this.companyForm.addresses=[]
      this.companyForm.addresses.push({
        address:(item.siege.numero_voie? item.siege.numero_voie +' ' : '')+(item.siege.type_voie? item.siege.type_voie +' ' : '')+item.siege.libelle_voie,
        addressComplement:item.siege.complement_adresse,
        zipCode: item.siege.code_postal,
        city: item.siege.libelle_commune,
        country: "",
        label: "Siège Social",
        isDefault: true,
        latitude:item.siege.latitude,
        longitude:item.siege.longitude,
        id:0
      });
      this.companyForm.name = item.nom_raison_sociale ? item.nom_raison_sociale: item.nom_complet
    },

    // Autocomplete Address from data.gouv
    autocompleteAddressSource: debounce(function(indexSource){
      let _this = this
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${this.companyForm.addresses[indexSource].address}&autocomplete=0`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.autocompleteAddressData = data.features;
          this.autocompleteAddressIndex = indexSource
        })
    }, 500),
    autocompleteAddressSelected (item) {
      this.companyForm.addresses[this.autocompleteAddressIndex].address=item.properties.name
      this.companyForm.addresses[this.autocompleteAddressIndex].city=item.properties.city
      this.companyForm.addresses[this.autocompleteAddressIndex].zipCode=item.properties.postcode 
      this.companyForm.addresses[this.autocompleteAddressIndex].latitude=item.geometry.coordinates[1] 
      this.companyForm.addresses[this.autocompleteAddressIndex].longitude=item.geometry.coordinates[0]

    },

    archiveCompanyLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.', {
          title:
            'Êtes-vous sûr de vouloir archiver le '+this.companyType.label.toLowerCase() +' "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Archiver',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.archiveCompany(id);
            if(this.$route.params.routeOrigine){
              this.$tabs.close({to : "/"+this.$route.params.routeOrigine})
            }else{
              this.$tabs.close()
            }
          }
        })
    },
    // Controls from
    cancel(){
      if(this.dataOrigine==JSON.stringify(this.companyForm)){
        this.$tabs.close()
        
      }else{
        this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir quitter l\'édition sans enregistrer ?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            if(this.$route.params.routeOrigine){
              this.$tabs.close({to : "/"+this.$route.params.routeOrigine})
            }else{
              this.$tabs.close()
            }
          }
        })
      }
    },

    newContact (id) { 
      if (this.companyForm.id){
        this.$router.push({ name: 'new-contact', params: { routeOrigine:'directory/edit-company/'+this.companyForm.id } })
      }else{
        this.$router.push({ name: 'new-contact', params: { routeOrigine:'directory/new-company/'+this.$route.params.name+'/'+this.$route.params.companyTypeId } })
      }
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingCompany",
      "isLoadingContact",
      "companiesTypesList",
      "contactsList",
    ])
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    VSwatches,
    Autocomplete,
    Trash2Icon, 
    StarIcon, 
    CheckCircleIcon
  },
  directives: {
    Ripple,
    mask,
    "b-tooltip": VBTooltip,
  }
}
</script>
<style lang="scss">
.content-scrollable-sticky{
  // display: grid; 
  // grid-template-columns: min-content 1fr; 
  position:relative;
  .content-scrollable-sticky-nav{
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>